import React from 'react';
import ReactDOM from 'react-dom';
import { setConfig } from '@namia/typography';
import './index.less';
import App from './App';
import typographyConfig from './typography-config.json';

setConfig(typographyConfig);

const element = document.getElementById('election-app-container');

if (element) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    element
  );
}

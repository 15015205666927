export function PrintCandidate({ candidate }) {
  const data = [];
  if (candidate.kunta) {
    data.push({
      title: candidate.kunta,
    });
  }
  if (candidate.ammattiliitto) {
    data.push({
      title: candidate.ammattiliitto,
    });
  }
  if (candidate.puolue) {
    data.push({
      title: candidate.puolue,
    });
  }
  return (
    <tr>
      <table className="print__candidate">
        <tr>
          <td></td>
          <td className="print__area" colspan="2">
            {candidate.alue}
          </td>
        </tr>
        <tr>
          <td className="print__number">{candidate.ehdokasnumero || ""}</td>
          <td className="print__name">{candidate.title.rendered}</td>
          <td className="print__detail">{data[0]?.title || ""}</td>
        </tr>
        <tr>
          <td rowspan="4"></td>
          <td className="print__title" rowspan="">
            {candidate.nimike}
          </td>
          <td className="print__detail">{data[1]?.title || ""}</td>
        </tr>
        <tr>
          <td></td>
          <td className="print__detail">{data[2]?.title || ""}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
      </table>
    </tr>
  );
}

import { delay, select, put, takeEvery } from "redux-saga/effects";
import { CLICK_PRINT, SET_PRINT_MODE } from "./actions";

export function* handleClickPrint() {
  const printMode = yield select((state) => state?.share?.printMode);
  if (printMode) {
    return;
  }
  yield put({ type: SET_PRINT_MODE, payload: true });
  yield delay(800);
  while (true) {
    document.querySelector("body").classList.add("no-scroll");
    const images = document.querySelectorAll("img");
    const loadingStates = Array.from(images).map((image) => image.complete);
    if (loadingStates.every((state) => state)) {
      break;
    }
    yield delay(500);
  }
  window.print();
  document.querySelector("body").classList.remove("no-scroll");
  yield put({ type: SET_PRINT_MODE, payload: false });
}

export default function* sagas() {
  yield takeEvery(CLICK_PRINT, handleClickPrint);
}

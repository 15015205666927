import styled from "@emotion/styled";
import { BOX_SHADOW } from "../../constants";
import colors from "../../colors";

// eslint-disable-next-line import/prefer-default-export
export const CandidateWrapper = styled.div`
  height: 100%;

  .ant-card-bordered {
    height: 100%;
    max-height: 100%;
    box-shadow: ${(props) => (props.print ? "none" : BOX_SHADOW)};
  }

  .ant-card-small > .ant-card-body {
    padding: 24px;
  }

  h1 {
    font-weight: 700;
  }

  .ant-card-small
    > .ant-card-head
    > .ant-card-head-wrapper
    > .ant-card-head-title {
    font-weight: 700;
  }

  .ant-list-item-meta-title {
    color: ${colors.gray08};
  }

  .ant-card-cover img {
    height: 450px;
    object-fit: cover;
    object-position: top;
    @media print {
      height: 6cm !important;
      max-height: 6cm !important;
    }
  }
`;

export const PlaceholderWrapper = styled.div`
  height: 450px;
  background: radial-gradient(
      84.33% 84.33% at 50% 50%,
      rgba(255, 255, 255, 0) 0%,
      #e1e1e1 100%
    ),
    #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #e0e0e1;
`;

export const PaginationWrapper = styled.div`
  .ant-pagination-options {
    display: none;
  }
  margin-bottom: 40px;
`;

export const LoadingWrapper = styled.div`
  padding: 16px;

  background: ${colors.gray01};
  margin: 0 auto;
  text-align: center;
`;

export const LoadingWrapperIcon = styled.div`
  padding: 16px;

  margin: 0 auto;
  text-align: center;
`;

export const SvgWrapper = styled.div`
  svg path {
    fill: rgb(196, 196, 196);
  }
  &:hover {
    svg path {
      fill: #0076be;
    }
  }
`;

export const SingleCandidate = styled.div`
  h3 {
    color: #262626;
    font-weight: 500;
  }
  p {
    color: #8c8c8c;
  }
`;

export const SocialShare = styled.div`
  @media print {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 auto;

  a {
    margin: 0 6px;
  }
`;

export const CandidateHeader = styled.div`
  margin-bottom: 24px;
`;

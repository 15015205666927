import React, { useMemo, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Col, Card, List, Row } from "antd";
import Typography from "@namia/typography";
import Loader from "react-loader-spinner";
import parse from "html-react-parser";
import { CandidateImg, CandidateText, BackButton } from "./styles";
import {
  ROW_GUTTER,
  INTRO_TEXT,
  INTRO_TEXT_SV,
  INTRO_TEXT_EN,
  GO_BACK_TEXT,
} from "../../constants";

import Social from "../Social";
import {
  LoadingWrapperIcon,
  CandidateWrapper,
  SingleCandidate,
  CandidateHeader,
  PlaceholderWrapper,
} from "../Candidates/styles";

function Candidate() {
  const { slug } = useParams();
  const candidates = useSelector((state) => state.candidates);
  const { printMode } = useSelector((state) => state.share);
  const history = useHistory();
  const scrollToRef = useRef();

  useEffect(() => {
    if (scrollToRef && scrollToRef.current) {
      scrollToRef.current.scrollIntoView();
    }
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const fCandidate = useMemo(
    () => (candidates ? candidates.filter((c) => c.slug === slug) : []),
    [candidates, slug]
  );

  const candidate = useMemo(() => fCandidate.find((c) => c.slug === slug), [
    fCandidate,
    slug,
  ]);

  // Hide content and hero.
  const $phpContent = document.getElementsByClassName("main__content")[0];
  const $logoReel = document.getElementsByClassName("logoreel")[0];
  if ($phpContent) {
    $phpContent.style.display = "none";
  }

  if ($logoReel) {
    $logoReel.style.display = "none";
  }

  if (!candidate) {
    return (
      <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
        <Col md={21} xs={22}>
          <LoadingWrapperIcon>
            <Loader
              type="Oval"
              color="#0076be"
              height={48}
              width={48}
              timeout={3000} // 3 secs
            />
          </LoadingWrapperIcon>
        </Col>
      </Row>
    );
  }

  let data = [
    {
      title: candidate.puolue,
    },
    {
      title: candidate.kunta,
    },
  ];

  const shouldPrintAmmattiliitto = (value) => {
    if (
      [
        "En kuulu ammattiliittoon",
        "Jag är inte medlem i något fackförbund",
        "I am not a member of a trade union",
      ].includes(value)
    ) {
      return false;
    }
    return !!value;
  };

  if (shouldPrintAmmattiliitto(candidate.ammattiliitto)) {
    data = [...data, { title: candidate.ammattiliitto }];
  }

  const { matches } = window.matchMedia("print");
  const isPrint = matches || printMode;
  return (
    <>
      {!isPrint && (
        <Row
          gutter={[ROW_GUTTER, ROW_GUTTER]}
          className="single-wrapper"
          ref={scrollToRef}
        >
          <Col xs={23}>
            <BackButton onClick={goBack}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6244 5.40625H2.48225L7.95412 0.65625C8.04162 0.579688 7.9885 0.4375 7.87287 0.4375H6.49006C6.42912 0.4375 6.37131 0.459375 6.326 0.498437L0.42131 5.62187C0.36723 5.66875 0.323858 5.72671 0.294132 5.79181C0.264407 5.85692 0.249023 5.92765 0.249023 5.99922C0.249023 6.07079 0.264407 6.14152 0.294132 6.20663C0.323858 6.27173 0.36723 6.32968 0.42131 6.37656L6.36037 11.5312C6.38381 11.5516 6.41193 11.5625 6.44162 11.5625H7.87131C7.98693 11.5625 8.04006 11.4187 7.95256 11.3438L2.48225 6.59375H11.6244C11.6932 6.59375 11.7494 6.5375 11.7494 6.46875V5.53125C11.7494 5.4625 11.6932 5.40625 11.6244 5.40625Z"
                  fill="#59606F"
                />
              </svg>
              <Typography type="basic" elementType="p" size="1">
                {GO_BACK_TEXT}
              </Typography>
            </BackButton>
          </Col>
        </Row>
      )}
      <Row gutter={[ROW_GUTTER, ROW_GUTTER]} className="single-low-top">
        <Col
          xs={isPrint ? 10 : 23}
          sm={isPrint ? 10 : 11}
          md={isPrint ? 10 : 11}
          lg={8}
          key={candidate.slug}
        >
          {candidate ? (
            <CandidateWrapper>
              <Card
                size="small"
                title={candidate.alue}
                extra={candidate.ehdokasnumero}
                cover={
                  candidate.fimg_url ? (
                    <CandidateImg alt="candidate" src={candidate.fimg_url} />
                  ) : (
                    <PlaceholderWrapper>
                      <svg
                        width="60"
                        height="64"
                        viewBox="0 0 60 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M57.0686 51.6562C55.5948 48.1654 53.4561 44.9946 50.7717 42.3203C48.0954 39.6384 44.925 37.5 41.4357 36.0234C41.4045 36.0078 41.3732 36 41.342 35.9844C46.2092 32.4688 49.3732 26.7422 49.3732 20.2812C49.3732 9.57812 40.7014 0.90625 29.9982 0.90625C19.2951 0.90625 10.6232 9.57812 10.6232 20.2812C10.6232 26.7422 13.7873 32.4687 18.6545 35.9922C18.6232 36.0078 18.592 36.0156 18.5607 36.0312C15.0607 37.5078 11.9201 39.625 9.22481 42.3281C6.54285 45.0044 4.40453 48.1748 2.92793 51.6641C1.47732 55.0801 0.694976 58.7425 0.623242 62.4531C0.621157 62.5365 0.635782 62.6195 0.666257 62.6971C0.696731 62.7747 0.742437 62.8455 0.800683 62.9052C0.858928 62.9649 0.928535 63.0123 1.0054 63.0447C1.08227 63.0771 1.16483 63.0938 1.24824 63.0938H5.93574C6.27949 63.0938 6.55293 62.8203 6.56074 62.4844C6.71699 56.4531 9.13887 50.8047 13.4201 46.5234C17.8498 42.0938 23.7326 39.6562 29.9982 39.6562C36.2639 39.6562 42.1467 42.0938 46.5764 46.5234C50.8576 50.8047 53.2795 56.4531 53.4357 62.4844C53.4436 62.8281 53.717 63.0938 54.0607 63.0938H58.7482C58.8316 63.0938 58.9142 63.0771 58.9911 63.0447C59.068 63.0123 59.1376 62.9649 59.1958 62.9052C59.254 62.8455 59.2998 62.7747 59.3302 62.6971C59.3607 62.6195 59.3753 62.5365 59.3732 62.4531C59.2951 58.7188 58.5217 55.0859 57.0686 51.6562ZM29.9982 33.7188C26.4123 33.7188 23.0373 32.3203 20.4982 29.7812C17.9592 27.2422 16.5607 23.8672 16.5607 20.2812C16.5607 16.6953 17.9592 13.3203 20.4982 10.7812C23.0373 8.24219 26.4123 6.84375 29.9982 6.84375C33.5842 6.84375 36.9592 8.24219 39.4982 10.7812C42.0373 13.3203 43.4357 16.6953 43.4357 20.2812C43.4357 23.8672 42.0373 27.2422 39.4982 29.7812C36.9592 32.3203 33.5842 33.7188 29.9982 33.7188Z"
                          fill="#EAEAEB"
                        />
                      </svg>
                    </PlaceholderWrapper>
                  )
                }
              >
                <SingleCandidate>
                  <CandidateHeader>
                    <Typography
                      type="tenso"
                      elementType="h3"
                      size={matches ? 2 : 3}
                    >
                      {candidate.title.rendered}
                    </Typography>
                    <Typography
                      type="basic"
                      elementType="p"
                      size={matches ? 1 : 2}
                    >
                      {candidate.nimike}
                    </Typography>
                  </CandidateHeader>
                  <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <svg
                              width="13"
                              height="14"
                              viewBox="0 0 13 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="6.5" cy="7" r="6.5" fill="#C4C4C4" />
                            </svg>
                          }
                          title={item.title}
                        />
                      </List.Item>
                    )}
                  />
                  <Social candidate={candidate} />
                </SingleCandidate>
              </Card>
            </CandidateWrapper>
          ) : null}
        </Col>

        <Col
          offset={isPrint ? 1 : 0}
          xs={isPrint ? 10 : 23}
          sm={isPrint ? 10 : 11}
          md={isPrint ? 10 : 11}
          lg={10}
        >
          <CandidateText>
            <Typography type="tenso" elementType="h1" size="2">
              {INTRO_TEXT}
            </Typography>
            {parse(candidate.content.rendered)}
          </CandidateText>

          {candidate.intro_sv && (
            <CandidateText>
              <Typography type="tenso" elementType="h1" size="2">
                {INTRO_TEXT_SV}
              </Typography>

              <Typography type="basic" elementType="p" size="2">
                {parse(candidate.intro_sv)}
              </Typography>
            </CandidateText>
          )}
          {candidate.intro_en && (
            <CandidateText>
              <Typography type="tenso" elementType="h1" size="2">
                {INTRO_TEXT_EN}
              </Typography>

              <Typography type="basic" elementType="p" size="2">
                {parse(candidate.intro_en)}
              </Typography>
            </CandidateText>
          )}
        </Col>
      </Row>
    </>
  );
}

export default Candidate;

import React from 'react';
import { SvgWrapper, SocialShare } from '../Candidates/styles';

/* eslint-disable react/prop-types */

// This prevents react router "Link" from overriding "a" link
const stopEvent = (event) => event.stopPropagation();

const Social = ({ candidate }) => (
  <SocialShare>
    {candidate.facebook && (
      <SvgWrapper>
        <a
          href={candidate.facebook}
          rel="noreferrer"
          target="_blank"
          onClick={stopEvent}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM16.4594 6.09766H14.9617C13.7875 6.09766 13.5602 6.65547 13.5602 7.47578V9.28281H16.3633L15.9977 12.1117H13.5602V19.375H10.6375V12.1141H8.19297V9.28281H10.6375V7.19687C10.6375 4.77578 12.1164 3.45625 14.2773 3.45625C15.3133 3.45625 16.2016 3.53359 16.4617 3.56875V6.09766H16.4594Z"
              fill="#E0E0E1"
            />
          </svg>
        </a>
      </SvgWrapper>
    )}
    {candidate.twitter && (
      <SvgWrapper>
        <a
          href={candidate.twitter}
          rel="noreferrer"
          target="_blank"
          onClick={stopEvent}
        >
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.7494 1.95968C19.0322 2.26905 18.2518 2.49171 17.4478 2.57843C18.2825 2.08257 18.9075 1.29921 19.2057 0.375303C18.4225 0.841215 17.5645 1.16792 16.6697 1.34093C16.2958 0.941146 15.8435 0.622662 15.3411 0.40532C14.8386 0.187979 14.2968 0.0764372 13.7494 0.0776464C11.5346 0.0776464 9.75332 1.87296 9.75332 4.07608C9.75332 4.38546 9.79082 4.69483 9.85176 4.99249C6.53535 4.81905 3.57754 3.23468 1.61113 0.808896C1.25283 1.42089 1.06507 2.11771 1.06738 2.82686C1.06738 4.21437 1.77285 5.4378 2.84863 6.15733C2.21466 6.13237 1.59553 5.95811 1.0416 5.64874V5.69796C1.0416 7.64093 2.41504 9.25108 4.24551 9.6214C3.90182 9.71067 3.54826 9.75634 3.19316 9.75733C2.93301 9.75733 2.68691 9.73155 2.43848 9.6964C2.94473 11.2808 4.41894 12.4316 6.17441 12.4691C4.80098 13.5448 3.08066 14.1776 1.2127 14.1776C0.877539 14.1776 0.568164 14.1659 0.24707 14.1284C2.01895 15.2651 4.12129 15.9214 6.38535 15.9214C13.7354 15.9214 17.7572 9.83233 17.7572 4.54718C17.7572 4.37374 17.7572 4.2003 17.7455 4.02687C18.5236 3.45733 19.2057 2.75187 19.7494 1.95968Z"
              fill="#E0E0E1"
            />
          </svg>
        </a>
      </SvgWrapper>
    )}

    {candidate.instagram && (
      <SvgWrapper>
        <a
          href={candidate.instagram}
          rel="noreferrer"
          target="_blank"
          onClick={stopEvent}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99913 6.87653C8.27882 6.87653 6.87492 8.28043 6.87492 10.0007C6.87492 11.7211 8.27882 13.125 9.99913 13.125C11.7194 13.125 13.1234 11.7211 13.1234 10.0007C13.1234 8.28043 11.7194 6.87653 9.99913 6.87653ZM19.3694 10.0007C19.3694 8.707 19.3812 7.42496 19.3085 6.13356C19.2359 4.63356 18.8937 3.30231 17.7968 2.20543C16.6976 1.10621 15.3687 0.766371 13.8687 0.693715C12.5749 0.621058 11.2929 0.632777 10.0015 0.632777C8.70773 0.632777 7.4257 0.621058 6.13429 0.693715C4.63429 0.766371 3.30304 1.10856 2.20617 2.20543C1.10695 3.30465 0.767103 4.63356 0.694447 6.13356C0.621791 7.42731 0.63351 8.70934 0.63351 10.0007C0.63351 11.2922 0.621791 12.5765 0.694447 13.8679C0.767103 15.3679 1.10929 16.6992 2.20617 17.7961C3.30538 18.8953 4.63429 19.2351 6.13429 19.3078C7.42804 19.3804 8.71007 19.3687 10.0015 19.3687C11.2952 19.3687 12.5773 19.3804 13.8687 19.3078C15.3687 19.2351 16.6999 18.8929 17.7968 17.7961C18.896 16.6968 19.2359 15.3679 19.3085 13.8679C19.3835 12.5765 19.3694 11.2945 19.3694 10.0007ZM9.99913 14.8078C7.33898 14.8078 5.1921 12.6609 5.1921 10.0007C5.1921 7.34059 7.33898 5.19371 9.99913 5.19371C12.6593 5.19371 14.8062 7.34059 14.8062 10.0007C14.8062 12.6609 12.6593 14.8078 9.99913 14.8078ZM15.003 6.1195C14.3819 6.1195 13.8804 5.61793 13.8804 4.99684C13.8804 4.37575 14.3819 3.87418 15.003 3.87418C15.6241 3.87418 16.1257 4.37575 16.1257 4.99684C16.1259 5.14432 16.097 5.29039 16.0406 5.42668C15.9843 5.56297 15.9016 5.68681 15.7973 5.79109C15.693 5.89538 15.5692 5.97806 15.4329 6.03442C15.2966 6.09077 15.1505 6.11968 15.003 6.1195Z"
              fill="#E0E0E1"
            />
          </svg>
        </a>
      </SvgWrapper>
    )}

    {candidate.linkedin && (
      <SvgWrapper>
        <a
          href={candidate.linkedin}
          rel="noreferrer"
          target="_blank"
          onClick={stopEvent}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM6.18672 16.6023H3.40469V7.65391H6.18672V16.6023ZM4.79688 6.43047C4.47795 6.43047 4.16619 6.3359 3.90102 6.15871C3.63584 5.98153 3.42917 5.72969 3.30712 5.43505C3.18507 5.1404 3.15314 4.81618 3.21536 4.50339C3.27758 4.19059 3.43115 3.90327 3.65667 3.67776C3.88218 3.45225 4.1695 3.29867 4.48229 3.23645C4.79509 3.17423 5.11931 3.20617 5.41395 3.32821C5.7086 3.45026 5.96044 3.65694 6.13762 3.92211C6.3148 4.18729 6.40937 4.49905 6.40937 4.81797C6.40703 5.70859 5.68516 6.43047 4.79688 6.43047ZM16.6023 16.6023H13.8227V12.25C13.8227 11.2117 13.8039 9.87812 12.3766 9.87812C10.9305 9.87812 10.7078 11.0078 10.7078 12.175V16.6023H7.93047V7.65391H10.5977V8.87734H10.6352C11.0055 8.17422 11.9125 7.43125 13.2672 7.43125C16.0844 7.43125 16.6023 9.28516 16.6023 11.6945V16.6023Z"
              fill="#E0E0E1"
            />
          </svg>
        </a>
      </SvgWrapper>
    )}

    {candidate.candidatesite && (
      <SvgWrapper>
        <a
          href={candidate.candidatesite}
          rel="noreferrer"
          target="_blank"
          onClick={stopEvent}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 16H2V2H9V0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V9H16V16ZM11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11Z"
              fill="#E0E0E1"
            />
          </svg>
        </a>
      </SvgWrapper>
    )}
  </SocialShare>
);

export default Social;

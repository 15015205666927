import { Action } from '../../types/redux';
import { SAVE_CANDIDATES } from './actions';

type State = {};

export const initialState = {};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case SAVE_CANDIDATES:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;

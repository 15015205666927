import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AutoComplete, Input, Radio, Select } from "antd";
import { useQuery } from "../../../helpers";
import { FilterSection } from "../styles";
import {
  ALL,
  NAME_SEARCH,
  MUNICIPALITY_SEARCH,
  MUNICIPALITY_SEARCH_HOLDER,
  COUNTY,
  CHOOSE_COUNTY,
} from "../../../constants";
import kuntaData from "./kunnat.json";
import countyData from "./counties.json";

/* eslint-disable react/prop-types */
function Search({
  setParentSearchValue,
  defaultSearchValue,
  defaultKuntaValue,
  setParentKuntaValue,
  defaultCountyValue,
  setParentCountyValue,
}) {
  const { Option } = Select;
  const dataSource = useSelector((state) => state.candidates);

  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  const [kuntaValue, setKuntaValue] = useState(defaultKuntaValue);
  const [countyValue, setCountyValue] = useState(defaultCountyValue);
  const [searchType, setSearchType] = useState("alue");

  const query = useQuery();
  const { print } = query;

  useEffect(() => {
    setParentCountyValue(countyValue);
    setParentSearchValue(searchValue);
    setParentKuntaValue(kuntaValue);
  }, [
    countyValue,
    setParentCountyValue,
    searchValue,
    setParentSearchValue,
    kuntaValue,
    setParentKuntaValue,
  ]);

  const onChange = (e) => {
    setSearchType(e.target.value);
    setSearchValue(null);
    setKuntaValue(null);
    setCountyValue(null);
  };

  if (!dataSource) {
    return null;
  }

  const listat = kuntaData.map((ob, idx) => ({
    value: ob,
    label: ob,
    key: idx,
  }));

  const options = dataSource.map((ob, idx) => ({
    value: ob.title.rendered,
    label: ob.title.rendered,
    key: idx,
  }));

  const { matches } = window.matchMedia("print");
  return (
    <>
      {!print && (
        <FilterSection>
          <Radio.Group onChange={onChange} value={searchType}>
            <Radio value="alue">{COUNTY}</Radio>
            <Radio value="kunta">{MUNICIPALITY_SEARCH}</Radio>
            {!matches && <Radio value="nimi">{NAME_SEARCH}</Radio>}
          </Radio.Group>
        </FilterSection>
      )}
      {searchType === "alue" && (
        <Select
          size="large"
          defaultValue={countyValue || CHOOSE_COUNTY}
          onChange={(value) => {
            setCountyValue(value);
          }}
          style={{ width: "100%" }}
        >
          <Option value="Kaikki">{ALL}</Option>
          {countyData.map((county) => (
            <Option value={county}>{county}</Option>
          ))}
        </Select>
      )}
      {searchType === "nimi" && (
        <AutoComplete
          style={{
            width: "100%",
          }}
          value={searchValue}
          onChange={(e, value) => {
            setSearchValue(value.value);
          }}
          defaultValue={
            options.find((o) => o.id && o.id === searchValue) || undefined
          }
          options={options}
          filterOption={(inputValue, option) =>
            option.label
              .toUpperCase()
              .indexOf(inputValue.toString().toUpperCase()) !== -1
          }
        >
          <Input.Search size="large" placeholder="Search" />
        </AutoComplete>
      )}
      {searchType === "kunta" && (
        <AutoComplete
          style={{
            width: "100%",
          }}
          value={kuntaValue}
          onChange={(event, value) => {
            setKuntaValue(value.value);
          }}
          options={listat}
          filterOption={(inputValue, option) =>
            option.label
              .toUpperCase()
              .indexOf(inputValue.toString().toUpperCase()) !== -1
          }
        >
          <Input.Search size="large" placeholder={MUNICIPALITY_SEARCH_HOLDER} />
        </AutoComplete>
      )}
    </>
  );
}

export default Search;

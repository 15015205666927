import React from "react";
import { Row, Col } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import { ROW_GUTTER } from "./constants";
import { Container } from "./App.styles";
import Candidate from "./components/Candidate";
import Candidates from "./components/Candidates";
import Share from "./components/Share";

import Filters from "./components/Filters";

import store from "./store";
import Main from "./Main";

function App() {
  // const { matches } = window.matchMedia("print");
  // const { printMode } = useSelector((state) => state.share);
  // const isPrint = matches || printMode;
  return (
    <Provider store={store}>
      <Main>
        <Router>
          <Container>
            <Switch>
              <Route path="/:slug" exact>
                <Row gutter={[ROW_GUTTER, ROW_GUTTER]} className="candidate">
                  <Col lg={22} offset={1}>
                    <Candidate />
                  </Col>
                </Row>
                <Share candidate />
              </Route>
              <Route path={["/:search/:kunta/:puolue/:liitto", ""]}>
                <Row gutter={[ROW_GUTTER, ROW_GUTTER]} className="election-app">
                  <Col lg={22} offset={1}>
                    <Filters />
                    <Candidates />
                  </Col>
                </Row>
                <Share />
              </Route>
            </Switch>
          </Container>
        </Router>
      </Main>
    </Provider>
  );
}

export default App;

export type Action = {
  type: string,
  payload?: Object,
};

/**
 * Helper for creating actions
 * @param  {string} action type
 * @param  {Object} action payload
 * @return {Object} type Action
 */
export const createAction = (type: string, payload?: Object): Action => ({
  type,
  payload,
});

import styled from "@emotion/styled";

// eslint-disable-next-line import/prefer-default-export
export const FilterSection = styled.div`
  padding: 16px 0;
  text-transform: uppercase;

  .ant-radio-wrapper {
    color: #303030;
    font-size: 12px;
  }

  p {
    color: #303030;
  }
`;
